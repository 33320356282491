<template>
  <b-card title="Categories">
   
    <b-tabs>
      
     <b-tab >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <a  href="">Add Category</a>
        </template>

       <AddCategory  @add-category="getData"></AddCategory>
      </b-tab>
      <b-tab @click="$router.push('/all-categories')"  >
        <template #title>
          <feather-icon icon="HomeIcon" />
           <!-- <a  href="/all-categories">All Categories</a> -->
           <span>All Categories</span>
        </template>
        <router-view></router-view>
        <!-- <CategoriesList ref="allData"></CategoriesList> -->
      </b-tab>
     
     
     
    </b-tabs>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'
import CategoriesList from './categoriesList.vue';
import AddCategory from './AddCategory.vue';
import { ref, reactive } from "@vue/composition-api";


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    CategoriesList,
    AddCategory
},
   setup() {
    const allData=ref()
  
    const getData=()=>{
    
      // allData.value.getCategories()
           

    }
   return{
    getData,
    allData,
   

   }
   
   }
}
</script>
